<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的课程</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ stu != "edit" ? "新增" : "修改" }}课程</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
               <div
              class="df"
              style="padding: 15px 0;justify-content: space-between;"
              v-show="ruleForm.auditState == '40'"
            >
              <div
                style="max-width:960px;flex-grow:0.6;padding:20px;background-color:rgb(253,237,237);border-radius:15px"
                class="auditReason"
              >
                <h3 style="color:rgb(189,63,63);margin-bottom:10px">
                  <i
                    style="font-weight:600;margin-right:6px"
                    class="el-icon-warning-outline"
                  ></i
                  >您的课程审核未通过，原因如下
                </h3>
                <p
                  style="font-size:12px;color:#999;padding:0 20px;letter-spacing:1px;width:100%;word-break: break-all;"
                >
                  {{ ruleForm.auditReason }}
                </p>
                <!-- <h3 style="padding-bottom: 10px">审核事由</h3>
                <el-input
                  type="textarea"
                  readonly
                  v-model="ruleForm.auditReason"
                ></el-input> -->
              </div>
            </div>
            <h3>课程基本信息</h3>
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="7.5rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="课程名称：" prop="courseName">
                  <el-input
                    v-model="ruleForm.courseName"
                    size="small"
                    maxlength="50"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                 <el-form-item
                label="课程分类："
                prop="miniappCourseType"
                class="form-item"
              >
                   <el-cascader
                    :options="miniCourseTypeList"
                    v-model="ruleForm.miniappCourseType"
                    :props="miniProps"
                    size="small"
                    clearable
                  ></el-cascader>
              </el-form-item>
                <el-form-item label="课程图片：" prop="thumbnail">
                  <el-upload
                    :on-change="handleAvatarSuccess2"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.thumbnail || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
                 <el-form-item label=" " class="form-item form-item-hint">
                <p>建议上传尺寸640*365。</p>
              </el-form-item>
              <el-form-item label="课程详情：" prop="context" class="form-item">
                <div
                  ref="editor"
                  class="editor"
                  style="width:100%;max-width:1200px"
                />
              </el-form-item>
              </el-form>
                <h3>课程章节</h3>
                <div style="display: flex; width: 100%" class>
                  <div style="width: 100%; margin-right: 25px">
                    <div class="btnbox">
                      <el-button
                        size="mini"
                        class="bgc-bv"
                        style="margin-right: 6px; padding: 10px"
                        round
                        v-if="!data.length"
                        @click="doChooseStyle"
                        >选择目录样式
                      </el-button>
                      <el-button
                        class="bgc-bv"
                        v-show="!data.length"
                        style="margin-right: 16px; padding: 10px"
                        size="mini"
                        round
                        @click="doTreeAppendone"
                        :disabled="disabled"
                        >新增一级项
                      </el-button>
                      <!-- <el-button class="bgc-bv" round @click="dialogVisible = false">快捷添加目录</el-button> 临时隐藏 -->
                    </div>
                    <section class="chapterl-trees createdCourse">
                      <el-tree
                        :data="data"
                        node-key="id"
                        default-expand-all
                        :draggable="draggable && !disabled"
                        :allow-drop="allowDrop"
                        :allow-drag="allowDrag"
                        v-if="data.length"
                        @node-drop="handleDrop"
                      >
                        <div
                          class="custom-tree-node"
                          slot-scope="{ node, data }"
                        >
                          <p class="flexac" v-if="data.level === 1">
                            <span style="width: 44px; text-align: left">
                              <span class="level" style="margin-right: 10px">
                                <!-- 一级 -->
                                {{ data.chapterNum }}
                              </span>
                            </span>
                            <el-input
                              ref="input"
                              size="mini"
                              style="width: 130px"
                              v-if="data.isEdit == 1"
                              @blur="submitEdit(node, data)"
                              @keyup.enter.native="handleEnter($event)"
                              v-model="treeLable"
                              maxlength="30"
                            />
                            <span
                              :title="data.catalogName"
                              class="ellipsis"
                              style="margin-right: 20px"
                              v-else
                              >{{ data.catalogName }}</span
                            >
                            <span class="trees-btns">
                              <el-button
                                size="mini"
                                style="padding: 7px 10px"
                                :disabled="disabled && !data.isEdit"
                                @click.stop="() => doTreeDel(node, data)"
                                >删除</el-button
                              >
                              <el-button
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => doTreeEdit(node, data)"
                                :disabled="disabled"
                                >重命名</el-button
                              >

                              <el-button
                                :disabled="disabled"
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => doAddCourseMore(node, data)"
                              >
                                <uploadAuthArr2
                                  :disabled="disabled"
                                  @onAddFile="onAddFile2"
                                  @fileUploadProgress2="uploadFileProgress2"
                                  @fileUploadSuccess2="uploadFileSuccess2"
                                  ref="batchUploader"
                                />
                              </el-button>
                              <el-button
                                :disabled="disabled"
                                size="mini"
                                style="padding: 7px 10px"
                                @click.stop="() => doTreeAppend(node, data)"
                                >新增二级项</el-button
                              >
                            </span>
                          </p>
                          <template v-else-if="data.level === 2">
                            <draggable
                              class="tree-drag-group"
                              :group="{
                                name: 'people',
                                put: true,
                                pull: false,
                              }"
                              :sort="false"
                              @change="
                                (evt) => {
                                  nodeChange(evt, data);
                                }
                              "
                              handle=".good"
                              :prevent-on-filter="true"
                            >
                              <span style="width: 60px; text-align: left">
                                <span
                                  class="level"
                                  v-if="styleType == '1'"
                                  style="margin-right: 10px"
                                >
                                  <!-- 二级 -->
                                  {{ data.chapterNum }}
                                </span>
                                <span
                                  class="level"
                                  v-else
                                  style="margin-right: 10px"
                                >
                                  <!-- 一级 -->
                                  {{ data.chapterNum }}
                                </span>
                              </span>
                              <el-input
                                ref="input"
                                size="mini"
                                style="width: 120px"
                                v-if="data.isEdit == 1"
                                @blur="submitEdit(node, data)"
                                @keyup.enter.native="handleEnter($event)"
                                v-model="treeLable"
                                maxlength="30"
                              />
                              <span
                                :title="data.catalogName"
                                class="ellipsis"
                                style="margin-right: 3px; width: 203px"
                                v-else
                                >{{ data.catalogName }}</span
                              >
                              <span class="trees-btns">
                                <el-button
                                  style="padding: 7px 10px"
                                  size="mini"
                                  :disabled="disabled && !data.isEdit"
                                  @click.stop="() => doTreeDel(node, data)"
                                  >删除</el-button
                                >
                                <el-button
                                  size="mini"
                                  style="padding: 7px 10px"
                                  @click.stop="() => doTreeEdit(node, data)"
                                  :disabled="disabled"
                                  >重命名</el-button
                                >

                                <el-button
                                  size="mini"
                                  style="padding: 7px 10px"
                                  @click.stop="() => doTreeTrysee(node, data)"
                                  :disabled="disabled"
                                  >{{
                                    data.trySee == false
                                      ? "开启试看"
                                      : "关闭试看"
                                  }}</el-button
                                >
                                <el-button
                                  :disabled="disabled"
                                  @click.stop="() => doAddCourse(node, data)"
                                  size="mini"
                                  style="padding: 7px 10px"
                                >
                                  <uploadAuthArr3
                                    :disabled="disabled"
                                    @onAddFile="onAddFile3"
                                    @fileUploadProgress2="uploadFileProgress2"
                                    @fileUploadSuccess2="uploadFileSuccess2"
                                    ref="batchUploader"
                                  />
                                </el-button>
                                <el-button
                                  size="mini"
                                  style="padding: 7px 10px"
                                  @click.stop="() => showVideo(data)"
                                  v-if="data.progress === 100"
                                  :disabled="disabled"
                                  >预览</el-button
                                >
                                <template v-else-if="data.progress">
                                  <el-progress
                                    :percentage="data.progress"
                                  ></el-progress>
                                </template>
                                <span v-else style="padding:0 15px"
                                  >等待上传</span
                                >
                                <el-button
                                  size="mini"
                                  @click="doAdd(node, data)"
                                  :disabled="disabled"
                                  >关联视频
                                </el-button>
                                <el-input
                                  ref="inputs"
                                  size="mini"
                                  style="width: 260px;padding-left:15px"
                                  v-if="data.isEdit == 2"
                                  @blur="submitvideoIdEdit(node, data)"
                                  @keyup.enter.native="
                                    handletvideoIdEnter($event)
                                  "
                                  v-model="treevideoId"
                                />
                              </span>
                            </draggable>
                          </template>
                        </div>
                      </el-tree>
                      <div v-else>
                        <p
                          style="padding: 50px; font-size: 14px"
                          v-if="textshow"
                        >
                          您还没有目录哦，首先要创建一个目录，你可以
                          <span
                            style="
                              color: #58B5DD;
                              font-size: 14px;
                              cursor: pointer;
                            "
                            @click="doChooseStyle"
                            >选择目录样式</span
                          >
                        </p>
                        <p style="padding: 50px; font-size: 14px" v-else>
                          您可以批量上传视频或逐个上传视频
                        </p>
                      </div>
                    </section>
                    <div
                      class="df"
                      style="
                        margin-right: 16px;
                        padding: 10px;
                        margin-bottom: 20px;
                      "
                    >
                      <el-button
                        v-show="data.length"
                        class="bgc-bv"
                        size="mini"
                        round
                        @click="doTreeAppendone"
                        :disabled="disabled"
                        >新增一级项
                      </el-button>
                      <uploadAuthArr
                        v-show="styleType == 2"
                        @onAddFile="onAddFile"
                        @fileUploadProgress2="uploadFileProgress2"
                        @fileUploadSuccess2="uploadFileSuccess2"
                        ref="batchUploader"
                      />
                    </div>
                  </div>
                </div>
                 <!-- <h3 style="padding-bottom:20px;">是否发布在平台销售</h3>
            <el-form ref="form" :model="form" label-width="0px">
              <el-form-item class="el-form-item saleForm">
                <el-radio-group
                  @change="radio"
                  v-model="ruleForm.salesState"
                  :disabled="this.courseOrigin == '20'"
                >
                  <el-radio label="20">是</el-radio>
                  <el-radio label="10">否</el-radio>
                </el-radio-group>
              </el-form-item>
              </el-form> -->
            </div>
            <div class="btn-box flexcc">
              <el-button @click="dohandleOk" class="bgc-bv">取 消</el-button>
               <el-button
                class="bgc-bv"
                @click="
                  () => {
                    doAddSave('ruleForm','10');
                  }
                "
                >保存
              </el-button>
              <!-- <el-button
                class="bgc-bv"
                @click="
                  () => {
                    doAddSave('ruleForm','20');
                  }
                "
                >提交审核
              </el-button> -->
              <!-- <el-button class="bgc-bv" @click="doAddSave('ruleForm')"
                >确 定</el-button
              >
              <el-button class="bgc-bv" @click="doAddSave('ruleForm')"
                >确 定</el-button
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <MenuStyle ref="MenuStyle" @eventBus="styleBack" />
     <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2 :videoId="videoId" :videoTime="videoTime" v-if="videoType" ref="player"/>
      <player_1 :videoId="videoId" :videoTime="videoTime" v-else ref="player"/>
    </el-dialog>
  </div>
</template>
<script>
import draggable from "@/utils/vuedraggable";
import MenuStyle from "@/views/resourse/popup/MenuStyle";
import uploadAuthArr from "@/components/uploadAuthArrkcf";
import uploadAuthArr2 from "@/components/uploadAuthArrkcf2";
import uploadAuthArr3 from "@/components/uploadAuthArrkcf3";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import E from "wangeditor";
let editor = {};
let updataTimer = null;
export default {
  name: "operate/accountEdit",
  components: {
    draggable,
    MenuStyle,
    uploadAuthArr,
    uploadAuthArr2,
    uploadAuthArr3,
    player_1,
player_2
  },
  data() {
    return {
      bbb: [],
      deletedChapter: [],
      stu: "add",
      courseId: "",
      dialogVisible: false,
      // 基本信息
    ruleForm: {
        courseName: "",
        thumbnailId: "",
        thumbnail: "",
        context: "", // 富文本
        salesState: "10",
        miniappCourseType: ""
      },
      rules: {
        courseName: [
          { required: true, message: "请输入课程名称", trigger: "blur" }
        ],
        miniappCourseType: [
          { required: true, message: "请选择课程分类：", trigger: "change" }
        ],
        thumbnail: [
          { required: true, message: "请上传课程图片", trigger: "change" }
        ],
        context: [
          { required: true, message: "请输入活动说明", trigger: "change" }
        ]
      },
      // tree
      hideUploadCard: false,
      draggable: true,
      treeLable: "",
      treevideoId: "",
      data: [],
      datas: {},
      isSelect: false,
      textshow: true,
      disabled: true,
      styleStu: false,
      addBtnIsOut: false,
      isChapter: false,
      uploadFileList: [],
      courseTypeList: [],
      coursesCourceList: [],
      styleType: 1,
      miniCourseTypeList:[], //培训类型
      miniProps: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
      dialogVisible1:false,
      videoType: null, // true-可以看；false-试看
      videotitle:"",
      videoTime:"",
      status: 1
    };
  },
  watch: {
    // 监听文件上传列表.每次更新都将上传成功的视频刷入缓存
    uploadFileList: {
      deep: true,
      handler(list) {
        let uploadedFiles = list.filter((file) => {
          return file.progress == 100;
        });
        localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
      },
    },
  },
  created() {
    this.stu = this.$route.query.stu;
    this.getcourseTypeArrData();
    this.init();
    if (this.stu == "edit") {
      this.courseId = this.$route.query.courseId;
      this.getDetail(this.courseId);
    }
    let filesStr = localStorage.getItem("uploadedFiles");
    if (filesStr) {
      this.uploadFileList = JSON.parse(filesStr);
      console.log(this.uploadFileList);
    }
  },
  mounted() {
    this.editorInit();
    this.init();
    const eWidth = this.$refs.editor.clientWidth;
    this.eWidth = eWidth;
  },
  computed: {},
  methods: {
    init() {
    },
     // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo" // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = html => {
        this.ruleForm.context = html;
      };
      editor.config.pasteTextHandle = function(content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
        //获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { hiddenState: "10" },
        3000,
        true,
        2
      ).then((ret) => {
        this.miniCourseTypeList = this.delchild(ret.data);
      });
    },
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    /**
     * 新增
     */
    doAddSave(formName,auditState) {
      console.log(this.ruleForm.thumbnail);
      const params = {
           auditState: auditState,
           
        miniCourse: {},
        miniCourseNodes: [],
      };
      params.miniCourse = {
           courseName: this.ruleForm.courseName,
        context: this.ruleForm.context,
        logo: this.ruleForm.thumbnailId,
        courseType:this.ruleForm.miniappCourseType,
        // salesState:this.ruleForm.salesState
     
      };
      if (this.courseId) {
        params.miniCourse.courseId = this.courseId;
        params.miniCourse.createTime = this.ruleForm.createTime.replaceAll(
          "/",
          "-"
        );
      }
     
     
      if (this.stu == "edit") {
        params.deletedChapter = this.deletedChapter;
      }
      if (this.data.length) {
        params.chapter = this.data[0].section == false ? false : true;
      }
      params.miniCourseNodes = this.data;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            this.stu != "edit"
              ? "/minicourse/insert"
              : "/minicourse/modify",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == "0") {
                if (this.stu != "edit") {
                  this.$message({
                    type: "success",
                    message: "新增成功",
                  });
                } else {
                  this.$message({
                    type: "success",
                    message: "修改成功",
                  });
                }
                this.dohandleOk();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // if (this.check()) {

      // }
    },

    /**
     * 编辑回显
     */
    getDetail(courseId) {
      this.$post(
        "/minicourse/getCourseInfo",
        { courseId },
        3000,
        true,
        2
      ).then((ret) => {
        let miniCourse = ret.data.miniCourse;
        let miniCourseNodes = ret.data.miniCourseNodes;
        let chapter = ret.data.chapter;
        this.isChapter = ret.data.chapter;
        this.deletedChapter = ret.data.deletedChapter || [];
        this.ruleForm = {
          ...this.ruleForm,
          courseName: miniCourse.courseName, //课程名称
          miniappCourseType: miniCourse.courseType, // 课程分类
           thumbnailId: miniCourse.logo,
              salesState: miniCourse.salesState,
              thumbnail: ret.data.courseCoverUrl,
               context: miniCourse.context,
               createTime:miniCourse.createTime,
               auditState:miniCourse.auditState,
               auditReason:miniCourse.auditReason || ''
        };
          editor.txt.html(miniCourse.context);
        this.disabled = false;
        if (chapter) {
          this.styleType = 1;
          miniCourseNodes.map((el) => {
            el.level = 1;
            el.children.map((els) => {
              els.level = 2;
              els.progress = 100;
            });
            this.data = miniCourseNodes;
          });
        } else {
          this.styleType = 2;
          miniCourseNodes.map((el) => {
            let moreCourse = [];
            moreCourse.push({
              ind: new Date().getTime(),
              level: 2,
              catalogName: el.catalogName,
              courseOutlineId: el.courseOutlineId,
              isEdit: "0",
              videoId: el.videoId,
              section: el.section,
              trySee: el.trySee,
              progress: 100,
            });
            this.data = [...this.data, ...moreCourse];
          });
        }
        this.mapData(this.data);
      });
      console.log(this.styleType);
    },
    // 添加
    doTreeAppendone() {
      this.disabled = true;
      this.styleStu = false;
      this.CreatNameBack();
    },
    doTreeAppend(node, data) {
      this.disabled = true;
      this.datas = data;
      this.styleStu = true;
      this.CreatNameBack();
      this.updataBack2Index();
    },
    /* 试看 */
    doTreeTrysee(node, data) {
      console.log(data);
      if (data.trySee) {
        this.$set(node, "trySee", !data.trySee);
        this.$set(data, "trySee", !data.trySee);
      } else {
        this.$set(node, "trySee", !data.trySee);
        this.$set(data, "trySee", !data.trySee);
      }
      //
    },
    //删除
    doTreeDel(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.indexOf(data);
      if (data.level == 1) {
        let name = "二级";
        if (this.styleType == 2) {
          name = "一级";
        }
        this.$confirm("你确定删除该一级项及下面所有二级项内容?", "删除一级项", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            children.splice(index, 1);
            this.deletedChapter.push(data.courseOutlineId);
            this.disabled = false;
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.mapData(this.data);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else if (data.level == 2) {
        this.$confirm("你确定删除该视频吗?", "删除一级项", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            children.splice(index, 1);
            this.deletedChapter.push(data.courseOutlineId);
            this.mapData(this.data);
            this.disabled = false;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
      this.updataBack2Index();
    },

    doTreeEdit(node, data) {
      this.treeLable = data.catalogName;
      this.disabled = true;
      this.$set(node, "isEdit", 1);
      this.$set(data, "isEdit", 1);
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    doAdd(node, data) {
      console.log("888", data);
      this.treevideoId = data.videoId;
      this.disabled = false;
      this.$set(node, "isEdit", 2);
      this.$set(data, "isEdit", 2);
      this.$nextTick(() => {
        this.$refs.inputs.focus();
      });
    },
    submitEdit(node, data) {
      // treeLable----目录名称
      if (this.treeLable.trim()) {
        this.$set(data, "catalogName", this.treeLable);
        this.$set(data, "isEdit", 0);
        this.$set(node, "isEdit", 0);
        this.treeLable = "";
        this.disabled = false;
      } else {
        this.$message.warning("请输入名称");
      }
    },
    submitvideoIdEdit(node, data) {
      // treeLable----目录名称
      if (this.treevideoId.trim()) {
        this.$set(data, "videoId", this.treevideoId);
        this.$set(data, "isEdit", 0);
        this.$set(node, "isEdit", 0);
        this.treevideoId = "";
        this.disabled = false;
      }
    },
    handletvideoIdEnter(event) {
      if (this.treevideoId.trim()) {
        event.target.blur();
      }
      // 回车失去焦点单独写 // 更新操作应该放到失去焦点的事件中，后面调用接口时重新写blur方法
    },
    handleEnter(event) {
      if (this.treeLable.trim()) {
        event.target.blur();
      } else {
        this.$message.warning("请输入名称");
      }
      // 回车失去焦点单独写 // 更新操作应该放到失去焦点的事件中，后面调用接口时重新写blur方法
    },
    // 批量上传视频
    courseTableMoreBack(ret) {
      console.log(ret);
      if (this.datas.catalogName == "") {
        this.$set(this.datas, "catalogName", ret.catalogName);
        this.$set(this.datas, "isEdit", 0);
        this.disabled = false;
      }
      const childBack = [];
      if (!this.addBtnIsOut) {
        // this.uploadFileProgress(ret)
        for (let i = 0; i < ret.length; i++) {
          const element = ret[i];
          console.log(ret[i]);
          childBack.push({
            ind: new Date().getTime(),
            level: 2,
            catalogName: element.catalogName,
            videoId: element.videoId,
            section: false,
            trySee: false,
          });
        }
        this.$set(this.datas, "children", [
          ...this.datas.children,
          ...childBack,
        ]);
        console.log(this.datas.children);
        console.log(this.childBack);
      } else {
        for (let i = 0; i < ret.length; i++) {
          const element = ret[i];
          const moreCourse = [];
          moreCourse.push({
            ind: new Date().getTime(),
            level: 2,
            catalogName: element.catalogName,
            isEdit: "0",
            videoId: element.videoId,
            section: false,
            trySee: 0,
            // list: [
            //   {
            //     ind: new Date().getTime(),
            //     name: element.name,
            //     kpointLessonNum: element.kpointLessonNum,
            //     videoId: element.videoId,
            //     progress:element.progress
            //   },
            // ],
          });
          this.data = [...this.data, ...moreCourse];
          console.log(this.data);
        }
      }
      this.mapData(this.data);
    },
    // 单个上传视频
    coursevideoBack(ret) {
      console.log(this.datas);
      console.log(ret);
      if (this.datas.catalogName == "") {
        this.$set(this.datas, "catalogName", ret.catalogName);
        this.$set(this.datas, "level", 2);
        this.$set(this.datas, "isEdit", 1);
        this.$set(this.datas, "videoId", ret.videoId);
        this.treeLable = this.datas.catalogName;
      } else {
        this.$set(this.datas, "catalogName", ret.catalogName);
        this.$set(this.datas, "level", 2);
        this.$set(this.datas, "isEdit", 0);
        this.$set(this.datas, "videoId", ret.videoId);
        this.treeLable = this.datas.catalogName;
      }
    },
    // 弹窗回调
    styleBack(ret) {
      this.disabled = true;
      this.styleType = ret;
      console.log(ret);
      if (ret == 1) {
        this.CreatNameBack();
      } else {
        this.textshow = false;
        this.disabled = false;
      }
    },
    // 填写目录名称
    CreatNameBack() {
      this.treeLable = "";
      this.treevideoId = "";
      if (this.styleStu) {
        // 样式1下的二级项
        const children = this.datas.children;
        children.push({
          ind: new Date().getTime(),
          level: 2,
          catalogName: "",
          isEdit: "1",
          children: [],
          list: [],
          section: true,
        });
        this.$set(this.datas, "children", children);
        this.styleStu = false;
      } else if (this.styleType == 1) {
        //样式1
        this.data.push({
          ind: new Date().getTime(),
          level: 1,
          catalogName: "",
          isEdit: "1",
          section: true,
          children: [],
        });
      } else {
        // 样式2
        this.data.push({
          ind: new Date().getTime(),
          level: 2,
          catalogName: "",
          isEdit: "1",
          section: false,
        });
        // this.disabled = false;
      }
      this.mapData(this.data);
    },
    doChooseStyle() {
      this.$refs.MenuStyle.showPopUp();
    },
    handleDragStart(node) {
      console.log("drag start", node);
    },
    handleDragEnter(draggingNode, dropNode) {
      console.log("tree drag enter: ", dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode) {
      console.log("tree drag leave: ", dropNode.label);
    },
    handleDragOver(draggingNode, dropNode) {
      console.log("tree drag over: ", dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      console.log("tree drag end: ", dropNode && dropNode.label, dropType);
    },
    handleDrop(draggingNode) {
      console.log("tree drag finish: ", draggingNode);
      this.mapData(this.data);
      this.updataBack2Index();
    },
    //  // 批量添加视频
    doAddCourseMore(node, data) {
      this.datas = data;
      if (data.catalogName == "") {
        this.$message({
          message: "请输入章节名称",
          type: "warning",
        });
      }
      this.addBtnIsOut = false;
      this.onAddFile2(file);
    },
    doAddCourse(node, data) {
      this.datas = data;
      this.addBtnIsOut = true;
    },
    onAddFile(file) {
      this.addBtnIsOut = true;
      this.uploadFileList.push(file);
      this.courseTableMoreBack(this.uploadFileList);
      this.uploadFileList = [];
    },
    onAddFile3(file) {
      this.addBtnIsOut = true;
      this.uploadFileList.push(file);
      this.coursevideoBack(file);
      this.uploadFileList = [];
    },
    onAddFile2(file) {
      this.uploadFileList = [];
      this.uploadFileList.push(file);
      this.courseTableMoreBack(this.uploadFileList);
      console.log("我去你把", this.uploadFileList);
    },
    uploadFileProgress(progress, videoId) {
      let tar = this.uploadFileList.find((file) => {
        file.videoId == videoId;
      });
      let formatedProgress = parseInt(progress * 100);
      if (tar) {
        this.$set(this.datas.children, "progress", formatedProgress);
        console.log("hehe", this.datas);
      } else {
        this.updateNodeProgress(this.datas, videoId, formatedProgress);
      }
    },
    uploadFileProgress2(progress, videoId) {
      console.log(videoId);
      console.log(this.uploadFileList);
      let tar = this.uploadFileList.find((file) => {
        file.videoId == videoId;
      });
      let formatedProgress = parseInt(progress * 100);
      if (tar) {
        this.$set(this.data, "progress", formatedProgress);
        console.log("wocao", this.data);
      } else {
        this.updateNodeProgress2(this.data, videoId, formatedProgress);
      }
    },
    updateNodeProgress2(parent, videoId, progress) {
      console.log(parent);
      for (let i = 0; i < parent.length; i++) {
        let item = parent[i];
        console.log(item);
        if (item.videoId == videoId) {
          this.$set(item, "progress", progress);
          return;
        }
        if (item.children) {
          this.updateNodeProgress(item.children, videoId, progress);
        }
      }
    },
    updateNodeProgress(parent, videoId, progress) {
      console.log(parent);
      for (let i = 0; i < parent.length; i++) {
        let item = parent[i];
        console.log(item);
        if (item.videoId == videoId) {
          this.$set(item, "progress", progress);
          return;
        }
        if (item.children) {
          this.updateNodeProgress(item.children, videoId, progress);
        }
        console.log("item", item);
      }
    },
    uploadFileSuccess(videoId) {
      this.uploadFileProgress(1, videoId);
    },
    uploadFileSuccess2(videoId) {
      this.uploadFileProgress2(1, videoId);
    },
    // tree中非同级 不能拖拽
    allowDrop(draggingNode, dropNode, type) {
      const tlevel = dropNode.data.level;
      const plevel = draggingNode.data.level;
      if (plevel === tlevel && type !== "inner") {
        // 同级
        return true;
      } else if (plevel === tlevel + 1 && type === "inner") {
        // 子集
        return true;
      } else {
        return false;
      }
    },
    allowDrag(draggingNode) {
      return draggingNode.data.catalogName.indexOf("三级 3-2-2") === -1;
    },
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        if (item.children) {
          item.children.forEach((el, k) => {
            el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
          });
        }
      });

      this.$nextTick(() => {
        this.data = data;
      });
    },
   
     // 预览
      showVideo(data) {
        console.log(data);
          let _this = this;
          //单元测试打开是题库
              _this
                  .$post("/biz/courseware/auth", {videoId: data.videoId})
                  .then(result => {
                      _this.videoId = data.videoId;
                      _this.videoType = result.data.trySee;
                      _this.videoTime = result.data.duration;
                      _this.videotitle = data.catalogName;
                      _this.dialogVisible1 = true;
                  })
      },
       handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      this.$refs.player.dd()
      distorySwigger()
    },
    /* 课程封面图 */
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.thumbnail = result.data.fileURL || "";
            this.ruleForm.thumbnailId = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    /* 课程推广图 */
    beforeAvatarUpload2(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          file.type
        ) === -1
      ) {
        that.$message.error("请上传正确的图片格式");
        return false;
      }
      var reader = new FileReader();
      reader.onload = function(event) {
        var txt = event.target.result;
        var img = document.createElement("img");
        img.src = txt;
        img.onload = function() {
          that.ruleForm.width = img.width || 375;
          that.ruleForm.height = img.height || 200;
          file.width = img.width;
          file.height = file.width;
        };
      };
      reader.readAsDataURL(file);

      return isLt2M;
    },
    updataBack2Index() {
      clearTimeout(updataTimer);
      updataTimer = setTimeout(this.updataIndexReset, 300);
    },

    /**
     * 返回
     */
    dohandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/miniApp/myResourcesList",
          query: {
            refresh: true,
          },
        });
      }, 300);
    },
  },
  beforeDestroy: function() {
    if (updataTimer) {
      clearTimeout(updataTimer);
    }
  },
};
</script>
<style lang="less" scoped>
.andDiscountPrice {
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
}
.price {
  display: flex;
  //   width: 45%;
  div {
    display: flex;
    span {
      text-align: right;
      margin-right: 0.25rem;
    }
  }
}
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}

.formCom {
  h3 {
    padding: 15px 0;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
    padding-left: 15px;
  }
  .chapterl-trees {
    padding: 15px 10px 15px 0;
    .el-tree-node__content {
      height: 36px;
      margin-bottom: 15px;
    }
    .draggable-item {
      margin-left: 10px;
      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      display: flex;
      align-items: center;
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      display: flex;
      align-items: center;
      width: 4rem;
      justify-content: space-between;
      i {
        color: #999999;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .el-icon-receiving {
    width: 42px;
    height: 42px;
    font-size: 40px;
    color: #999;
    margin-right: 15px;
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      width: 10rem;
      span {
        min-width: 4em;
      }
    }
  }
  .file-list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .tree-drag-group {
    display: flex;
    align-items: center;
    font-size: 14px;
    .list-group-item {
      margin-left: 5px;
      display: inline-block;
      .el-icon-receiving {
        font-size: 22px;
        color: #2d2;
      }
      .df {
        display: none;
      }
    }
  }
  .bgc-height {
    background-color: #f4ff00;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 148px !important;
    height: 148px !important;
  }
  .hide /deep/.el-upload--picture-card {
    display: none;
  }
}

.btnbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.courseDescription {
  /deep/.el-textarea__inner {
    min-height: 120px !important;
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.bgc-bv:disabled, .bgc-bv:disabled:hover, .bgc-bv:focus, .bgc-bv:hover {
     background-color: #6676ff;
    border-color: #6676ff;
    color: #fff;
}
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
.saleForm {
    .el-form-item__content{
        margin-left: 0 !important;
    }
}
</style>
